@import "assets/styles/variables";

#home-page {


    .first-home-section {

        .div-content-style {

            h4 {}

            p {
                line-height: 2.5 !important;
                text-align: justify;
                font-size: 15px;
                font-weight: 700;


                @media only screen and (max-width: 991px) {
                    line-height: 2 !important;
                    font-size: 14px;
                }

            }
        }
    }

    .second-home-section {
        padding-top: 30px;
        padding-bottom: 50px;
        margin-top: 50px;
        background-color: theme-color('bg');

        .div-content-style {

            h4 {}

            p {
                line-height: 2.5 !important;
                text-align: justify;
                font-size: 15px;
                font-weight: 700;


                @media only screen and (max-width: 991px) {
                    line-height: 2 !important;
                    font-size: 14px;
                }

            }
        }
    }

    .third-home-section {
        padding-top: 30px;

        .div-content-style {

            h4 {}

            p {
                line-height: 2.5 !important;
                text-align: justify;
                font-size: 15px;
                font-weight: 700;


                @media only screen and (max-width: 991px) {
                    line-height: 2 !important;
                    font-size: 14px;
                }

            }
        }
    }

    .four-home-section {
        padding-top: 50px;
        padding-bottom: 50px;
        margin-top: 30px;
        background-color: theme-color('bg');

        .div-content-style {

            h4 {}

            li {
                list-style-image: url('../../../public/Images/circle.png');
                font-weight: 700;
                font-size: 15px;
            }
        }
    }

    .five-home-section {

        .title-center-style {
            position: absolute;
            top: 48%;
            right: 43%;
            color: theme-color('primary');
            font-weight: 900;
            font-size: 28px;

            @media only screen and (max-width: 1199px) {
                font-size: 18px;
            }

            @media only screen and (max-width: 991px) {
                top: 48%;
                right: 38%;
                font-size: 14px;
            }

            @media only screen and (max-width: 576px) {
                top: 48%;
                right: 35%;
                font-size: 10px;
            }
        }

        .title1-style {
            position: absolute;
            top: 0%;
            right: 70%;
            color: theme-color('primary');
            font-weight: bold;

            @media only screen and (max-width: 1199px) {
                font-size: 18px;
            }

            @media only screen and (max-width: 991px) {
                top: 0%;
                right: 70%;
                font-size: 14px;
            }

            @media only screen and (max-width: 576px) {
                top: 0%;
                right: 70%;
                font-size: 10px;
            }
        }

        .gif2-style {
            position: absolute;
            top: 3.5%;
            right: 70%;
            width: 7%;

            @media only screen and (max-width: 767px) {
                top: 6%;
                right: 72%;
            }

            @media only screen and (max-width: 576px) {
                top: 4%;
                right: 70%;
            }

            @media only screen and (max-width: 375px) {
                top: 4%;
                right: 72%;
            }

            @media only screen and (max-width: 375px) {
                top: 6%;
                right: 73%;
            }
        }

        .title2-style {
            position: absolute;
            top: 3%;
            right: 12%;
            color: theme-color('primary');
            font-weight: bold;

            @media only screen and (max-width: 1199px) {
                font-size: 18px;
            }

            @media only screen and (max-width: 991px) {
                top: 3%;
                right: 12%;
                font-size: 14px;
            }

            @media only screen and (max-width: 576px) {
                top: 3%;
                right: 12%;
                font-size: 10px;
            }
        }

        .gif1-style {
            position: absolute;
            top: 7%;
            right: 12%;
            width: 6%;

            @media only screen and (max-width: 375px) {
                top: 9%;
                right: 13%;
                width: 5%;
            }
        }

        .title3-style {
            position: absolute;
            top: 25%;
            left: -10%;
            color: theme-color('primary');
            font-weight: bold;

            @media only screen and (max-width: 1199px) {
                font-size: 18px;
            }

            @media only screen and (max-width: 991px) {
                top: 25%;
                left: -10%;
                font-size: 14px;
            }

            @media only screen and (max-width: 576px) {
                top: 20%;
                left: -4%;
                font-size: 10px;
            }
        }

        .gif3-style {
            position: absolute;
            top: 30%;
            left: -8%;
            width: 9%;

            @media only screen and (max-width: 576px) {
                top: 25%;
                left: -3%;
                width: 10%;
            }

            @media only screen and (max-width: 320px) {
                top: 28%;
                left: -1%;
                width: 8%;
            }
        }

        .title4-style {
            position: absolute;
            bottom: 15%;
            right: -11%;
            color: theme-color('primary');
            font-weight: bold;

            @media only screen and (max-width: 1199px) {
                font-size: 18px;
            }

            @media only screen and (max-width: 991px) {
                bottom: 15%;
                right: -10%;
                font-size: 14px;
            }

            @media only screen and (max-width: 576px) {
                bottom: 11%;
                right: -3%;
                font-size: 10px;
            }

            @media only screen and (max-width: 425px) {
                bottom: 9%;
                right: -3%;
                width: 8%;
            }
        }

        .gif5-style {
            position: absolute;
            bottom: 20%;
            right: -9%;
            width: 10%;

            @media only screen and (max-width: 576px) {
                bottom: 15%;
                right: -3%;
                width: 6%;
            }

        }

        .title5-style {
            position: absolute;
            bottom: 5%;
            left: 13%;
            color: theme-color('primary');
            font-weight: bold;

            @media only screen and (max-width: 1199px) {
                bottom: 5%;
                left: 13%;
                font-size: 18px;
            }

            @media only screen and (max-width: 991px) {
                bottom: 5%;
                left: 13%;
                font-size: 14px;
            }

            @media only screen and (max-width: 991px) {
                bottom: 5%;
                left: 12%;
                font-size: 12px;
            }

            @media only screen and (max-width: 991px) {
                bottom: 4%;
                left: 10%;
                font-size: 12px;
            }

            @media only screen and (max-width: 576px) {
                bottom: 5%;
                left: 12%;
                font-size: 10px;
            }

            @media only screen and (max-width: 425px) {
                bottom: 3%;
                left: 8%;
                font-size: 10px;
            }

            @media only screen and (max-width: 375px) {
                bottom: 3%;
                left: 6%;
                font-size: 10px;
            }

            @media only screen and (max-width: 320px) {
                bottom: 3%;
                left: 5%;
                font-size: 10px;
            }
        }

        .gif4-style {
            position: absolute;
            bottom: 11%;
            left: 18%;
            width: 12%;

            @media only screen and (max-width: 576px) {
                bottom: 11%;
                left: 18%;
                width: 10%;
            }

            @media only screen and (max-width: 320px) {
                bottom: 11%;
                left: 18%;
                width: 8%;
            }
        }
    }

    .six-home-section {
        padding-top: 30px;
        // padding-bottom: 30px;

        @media only screen and (max-width: 991px) {
            padding-top: 0;
            padding-bottom: 0;
        }

        .title-center-style {
            position: absolute;
            bottom: 14%;
            right: 40%;
            color: theme-color('white');
            font-weight: bold;

            &:dir(ltr) {
                right: 0;
                left: 40%;
            }

            @media only screen and (max-width: 1400px) {
                bottom: 14%;
                right: 40%;
                font-size: 18px;
            }

            @media only screen and (max-width: 1199px) {
                bottom: 13%;
                right: 40%;
                font-size: 18px;
            }

            @media only screen and (max-width: 991px) {
                bottom: 15%;
                right: 38%;
                font-size: 14px;
            }

            @media only screen and (max-width: 767px) {
                bottom: 15%;
                right: 37%;
                font-size: 12px;
            }

            @media only screen and (max-width: 576px) {
                bottom: 15%;
                right: 37%;
                font-size: 10px;
            }

            @media only screen and (max-width: 375px) {
                bottom: 15%;
                right: 37%;
                font-size: 8px;
            }
        }

        .title1-style {
            position: absolute;
            top: 25%;
            right: 3.5%;
            color: theme-color('secondary');
            font-weight: bold;
            width: 12% !important;
            text-align: center;


            @media only screen and (max-width: 1400px) {
                top: 25%;
                right: 3.5%;
                font-size: 18px;
            }

            @media only screen and (max-width: 1199px) {
                top: 25%;
                right: 3.5%;
                font-size: 18px;

                &:dir(ltr) {
                    width: 14% !important;
                    right: 3%;
                }
            }

            @media only screen and (max-width: 991px) {
                top: 33%;
                right: 4.5%;
                font-size: 14px;

                &:dir(ltr) {
                    width: 14% !important;
                    right: 3.5%;
                }
            }

            @media only screen and (max-width: 767px) {
                top: 33%;
                right: 5.5%;
                font-size: 12px;

                &:dir(ltr) {
                    width: 16% !important;
                    right: 4%;
                }
            }

            @media only screen and (max-width: 576px) {
                top: 33%;
                right: 5%;
                font-size: 10px;
                width: 14% !important;
            }

            @media only screen and (max-width: 767px) {
                top: 33%;
                right: 5%;
                font-size: 10px;
                width: 14% !important;

                &:dir(ltr) {
                    width: 17% !important;
                    right: 3.5%;
                    font-size: 9px;
                }
            }

            @media only screen and (max-width: 375px) {
                top: 33%;
                right: 5%;
                font-size: 8px;

                &:dir(ltr) {
                    width: 17% !important;
                    right: 3.5%;
                    font-size: 7px;
                }
            }
        }

        .title2-style {
            position: absolute;
            top: 51%;
            left: 3.5%;
            color: theme-color('secondary');
            font-weight: bold;
            text-align: center;

            @media only screen and (max-width: 1400px) {
                top: 51%;
                left: 4%;
                font-size: 18px;
            }

            @media only screen and (max-width: 1199px) {
                top: 51%;
                left: 3.5%;
                font-size: 18px;
            }

            @media only screen and (max-width: 991px) {
                top: 53%;
                left: 4.5%;
                font-size: 14px;
            }

            @media only screen and (max-width: 767px) {
                top: 54%;
                left: 5%;
                font-size: 12px;
            }

            @media only screen and (max-width: 576px) {
                top: 54%;
                left: 5%;
                font-size: 10px;
            }

            @media only screen and (max-width: 425px) {
                top: 54%;
                left: 4%;
                font-size: 10px;
            }

            @media only screen and (max-width: 375px) {
                top: 54%;
                left: 5%;
                font-size: 8px;

                &:dir(ltr) {
                    top: 54%;
                    left: 4%;

                }
            }
        }

        .title3-style {
            position: absolute;
            top: 58%;
            right: 39.5%;
            color: theme-color('secondary');
            font-weight: bold;
            text-align: center;
            font-size: 18px;

            &:dir(ltr) {
                right: 38.5%;
            }

            @media only screen and (max-width: 1400px) {
                top: 58%;
                right: 39.5%;
                font-size: 18px;

                &:dir(ltr) {
                    right: 38%;
                }
            }

            @media only screen and (max-width: 1199px) {
                top: 58%;
                right: 39.5%;
                font-size: 16px;

                &:dir(ltr) {
                    right: 38%;
                }
            }

            @media only screen and (max-width: 991px) {
                top: 54%;
                right: 37%;
                font-size: 14px;

                &:dir(ltr) {
                    right: 35%;
                }
            }

            @media only screen and (max-width: 767px) {
                top: 54%;
                right: 35%;
                font-size: 12px;

                &:dir(ltr) {
                    right: 33%;
                }
            }

            @media only screen and (max-width: 576px) {
                top: 54.5%;
                right: 35.5%;
                font-size: 10px;

                &:dir(ltr) {
                    right: 34%;
                    font-size: 9px;
                }
            }

            @media only screen and (max-width: 425px) {
                top: 54.5%;
                right: 35.5%;
                font-size: 10px;

                &:dir(ltr) {
                    right: 33.5%;
                    font-size: 8px;
                }
            }

            @media only screen and (max-width: 375px) {
                top: 54%;
                right: 35%;
                font-size: 8px;

                &:dir(ltr) {
                    top: 54.5%;
                    right: 33.5%;
                    font-size: 6px;
                }
            }
        }

        .title4-style {
            position: absolute;
            top: 58%;
            right: 49%;
            color: theme-color('secondary');
            font-weight: bold;
            text-align: center;
            font-size: 18px;

            &:dir(ltr) {
                right: 48%;
            }

            @media only screen and (max-width: 1400px) {
                top: 58%;
                right: 49%;
                font-size: 18px;
            }

            @media only screen and (max-width: 1199px) {
                top: 58%;
                right: 49%;
                font-size: 16px;
            }

            @media only screen and (max-width: 991px) {
                top: 54%;
                right: 48%;
                font-size: 14px;

                &:dir(ltr) {
                    right: 47.5%;
                }
            }

            @media only screen and (max-width: 767px) {
                top: 54%;
                right: 48%;
                font-size: 12px;

                &:dir(ltr) {
                    right: 47%;
                }
            }

            @media only screen and (max-width: 576px) {
                top: 54.5%;
                right: 48%;
                font-size: 10px;
            }

            @media only screen and (max-width: 425px) {
                top: 54.5%;
                right: 48%;
                font-size: 10px;

                &:dir(ltr) {
                    font-size: 8px;
                }
            }

            @media only screen and (max-width: 375px) {
                top: 54%;
                right: 48%;
                font-size: 8px;

                &:dir(ltr) {
                    top: 54.5%;
                    font-size: 6px;
                }
            }
        }

        .title5-style {
            position: absolute;
            top: 58%;
            right: 57.5%;
            color: theme-color('secondary');
            font-weight: bold;
            text-align: center;
            font-size: 18px;

            &:dir(ltr) {
                right: 56.5%;
            }

            @media only screen and (max-width: 1400px) {
                top: 58%;
                right: 57.5%;
                font-size: 18px;

                &:dir(ltr) {
                    right: 56.3%;
                    font-size: 16px;
                }
            }

            @media only screen and (max-width: 1199px) {
                top: 58%;
                right: 57%;
                font-size: 16px;

                &:dir(ltr) {
                    right: 56.3%;
                    font-size: 14px;
                }
            }

            @media only screen and (max-width: 991px) {
                top: 54%;
                right: 58.5%;
                font-size: 14px;

                &:dir(ltr) {
                    top: 54.5%;
                    right: 57.8%;
                    font-size: 12px;
                }
            }

            @media only screen and (max-width: 767px) {
                top: 54%;
                right: 59.5%;
                font-size: 12px;

                &:dir(ltr) {
                    top: 54.5%;
                    right: 58.7%;
                    font-size: 10px;
                }
            }

            @media only screen and (max-width: 576px) {
                top: 54.5%;
                right: 60%;
                font-size: 10px;

                &:dir(ltr) {
                    top: 55%;
                    right: 59%;
                    font-size: 8px;
                }
            }

            @media only screen and (max-width: 576px) {
                top: 54.5%;
                right: 60%;
                font-size: 10px;

                &:dir(ltr) {
                    top: 55%;
                    right: 59%;
                    font-size: 7px;
                }
            }

            @media only screen and (max-width: 375px) {
                top: 54%;
                right: 60%;
                font-size: 8px;

                &:dir(ltr) {
                    top: 55%;
                    right: 58.9%;
                    font-size: 6px;
                }
            }
        }
    }

    .seven-home-section {
        // padding-top: 30px;
        padding-bottom: 30px;
    }

    .eight-home-section {
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;


        .swiper-slide-next {
            z-index: -1 !important;
        }

        .card-style-custom {
            width: 280px;
            height: 450px;

            @media only screen and (max-width: 1399px) {
                width: 250px;
                height: 435px;
            }

            @media only screen and (max-width: 1199px) {
                width: 230px;
                height: 435px;
            }

            @media only screen and (max-width: 991px) {
                width: 210px;
            }


            .feature-icon-style {
                width: 12px;
                height: auto;
            }
        }

        .swiper {
            user-select: none;
            width: 100%;
            height: 100% !important;
            overflow: visible;
            padding: 1% 0;

            @media only screen and (max-width: 575px) {
                width: 50%;
                padding: 8% 0;
            }
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: none;

            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .swiper-button-prev {
            color: black !important;
            top: 10px;
            background-image: url(../../../public/Images/arrow.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center;
            transform: scale(-1, 1);

            &::after {
                display: none;
            }

            @media only screen and (max-width: 1199px) {
                top: 10px;
            }

            @media only screen and (max-width: 991px) {
                top: -10px;
                left: 5;
            }

            @media only screen and (max-width: 767px) {
                top: -30px;
                right: 5px;
            }

            @media only screen and (max-width: 575px) {
                top: -10px;
                left: 0;
            }

            @media only screen and (max-width: 425px) {
                top: -10px;
                left: -45px;
            }
        }

        .swiper-button-next {
            color: black;
            top: 10px;
            background-image: url(../../../public/Images/arrow.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center;

            &::after {
                display: none;
            }

            @media only screen and (max-width: 1199px) {
                top: 10px;
            }

            @media only screen and (max-width: 991px) {
                top: -10px;
                right: 5px;
            }

            @media only screen and (max-width: 767px) {
                top: -30px;
                right: 5px;
            }

            @media only screen and (max-width: 575px) {
                top: -10px;
                right: 0;
            }

            @media only screen and (max-width: 425px) {
                top: -10px;
                right: -45px;
            }
        }

        // background-price
        .background-price {
            background-color: theme-color('bg');
            width: 100%;
            height: 250px;
            position: absolute;
            top: 40%;
            z-index: -1;
        }
    }

    .nine-home-section {
        padding-top: 80px;
        padding-bottom: 80px;


        .title1 {
            position: absolute;
            bottom: 28%;
            right: 10%;
            font-weight: bolder;
            color: theme-color('secondary');

            &:dir(ltr) {
                bottom: 25%;
            }

            @media only screen and (max-width: 1199px) {
                font-size: 20px;
                right: 10.5%;

                &:dir(ltr) {
                    right: 11%;
                }
            }

            @media only screen and (max-width: 991px) {
                font-size: 18px;
                right: 10%;

                &:dir(ltr) {
                    right: 10%;
                }
            }

            @media only screen and (max-width: 767px) {
                font-size: 15px;
                right: 10%;

                &:dir(ltr) {
                    right: 9%;
                }
            }

            @media only screen and (max-width: 576px) {
                font-size: 12px;
                right: 10%;
            }

            @media only screen and (max-width: 375px) {
                font-size: 10px;
                right: 9.5%;
                bottom: 25%;

                &:dir(ltr) {
                    font-size: 8px;
                }
            }
        }

        .title2 {
            position: absolute;
            bottom: 28%;
            right: 23.5%;
            font-weight: bolder;
            color: theme-color('secondary');

            &:dir(ltr) {
                bottom: 26%;
                right: 22.5%;
            }

            @media only screen and (max-width: 1399px) {

                &:dir(ltr) {
                    bottom: 26%;
                    right: 21.5%;
                }
            }

            @media only screen and (max-width: 1199px) {
                font-size: 20px;
                right: 23.5%;

                &:dir(ltr) {
                    right: 22.5%;
                }
            }

            @media only screen and (max-width: 991px) {
                font-size: 18px;
                right: 23.5%;

                &:dir(ltr) {
                    right: 22%;
                }
            }

            @media only screen and (max-width: 767px) {
                font-size: 15px;
                right: 22.5%;

                &:dir(ltr) {
                    right: 21%;
                }
            }

            @media only screen and (max-width: 576px) {
                font-size: 12px;
                right: 23%;
            }

            @media only screen and (max-width: 576px) {
                font-size: 12px;
                right: 23%;

                &:dir(ltr) {
                    right: 22%;
                    font-size: 10px;
                }
            }

            @media only screen and (max-width: 375px) {
                font-size: 10px;
                right: 22%;
                bottom: 28%;

                &:dir(ltr) {
                    font-size: 8px;
                }
            }
        }

        .title3 {
            position: absolute;
            bottom: 33%;
            right: 36%;
            font-weight: bolder;
            color: theme-color('secondary');

            &:dir(ltr) {
                bottom: 27%;
                right: 36%;
            }

            @media only screen and (max-width: 1199px) {
                font-size: 20px;
                right: 36%;
            }

            @media only screen and (max-width: 991px) {
                font-size: 18px;
                right: 36%;
            }

            @media only screen and (max-width: 767px) {
                font-size: 15px;
                right: 36%;
            }

            @media only screen and (max-width: 576px) {
                font-size: 12px;
                right: 35%;
            }

            @media only screen and (max-width: 576px) {
                font-size: 12px;
                right: 35%;

                &:dir(ltr) {
                    font-size: 10px;
                }
            }

            @media only screen and (max-width: 375px) {
                font-size: 10px;
                right: 35%;
                bottom: 28%;

                &:dir(ltr) {
                    font-size: 8px;
                }
            }
        }

        .title4 {
            position: absolute;
            bottom: 15%;
            right: 51%;
            font-weight: bolder;
            color: theme-color('secondary');

            @media only screen and (max-width: 1199px) {
                font-size: 20px;
                right: 51%;
            }

            @media only screen and (max-width: 991px) {
                font-size: 18px;
                right: 51%;
            }

            @media only screen and (max-width: 767px) {
                font-size: 15px;
                right: 50%;
            }

            @media only screen and (max-width: 576px) {
                font-size: 12px;
                right: 50%;
            }

            @media only screen and (max-width: 576px) {
                font-size: 12px;
                right: 50%;

                &:dir(ltr) {
                    font-size: 10px;
                    right: 51.5%;
                }
            }

            @media only screen and (max-width: 375px) {
                font-size: 10px;
                right: 50%;
                bottom: 14%;

                &:dir(ltr) {
                    font-size: 8px;
                }
            }
        }

        .title5 {
            position: absolute;
            bottom: 23%;
            right: 63%;
            font-weight: bolder;
            color: theme-color('secondary');

            @media only screen and (max-width: 1199px) {
                font-size: 20px;
                right: 63%;
            }

            @media only screen and (max-width: 991px) {
                font-size: 18px;
                right: 63%;
            }

            @media only screen and (max-width: 767px) {
                font-size: 15px;
                right: 63%;
            }

            @media only screen and (max-width: 576px) {
                font-size: 12px;
                right: 63%;
            }

            @media only screen and (max-width: 425px) {
                font-size: 12px;
                right: 63%;

                &:dir(ltr) {
                    font-size: 10px;
                    right: 62.5%;
                }
            }

            @media only screen and (max-width: 375px) {
                font-size: 10px;
                right: 63%;
                bottom: 14%;

                &:dir(ltr) {
                    font-size: 8px;
                }
            }
        }
    }
}