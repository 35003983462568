@import "assets/styles/variables";

#header {
  background-color: theme-color('background');
  height: 100%;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding: 30px 0;


  .logo-style {
    width: 30%;

    @media only screen and (max-width: 991px) {
      width: 40%;
    }

    @media only screen and (max-width: 767px) {
      width: 50%;
    }

    @media only screen and (max-width: 425px) {
      width: 60%;
    }
  }

  .horizontal-line {
    width: 100%;
    height: 5px;
    background-color: theme-color('bg');
    margin: 30px 0 20px 0;
    border-radius: 5px;
  }
}
