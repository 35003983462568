@import "assets/styles/variables";

#footer {
    text-align: center;

    .logo-section {
        background-color: theme-color('bg');
        height: 100%;
        max-width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 120px 0;


        img {
            height: 100%;
            width: 30%;

            @media only screen and (max-width: 991px) {
                width: 25%;
                padding: 0px 0;
            }

            @media only screen and (max-width: 767px) {
                width: 30%;
            }

            @media only screen and (max-width: 576px) {
                width: 35%;
            }

            @media only screen and (max-width: 425px) {
                width: 50%;
            }
        }
    }


    .last-section {
        background-color: theme-color('primary');
        height: 30px;
        max-width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}