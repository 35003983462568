@import "assets/styles/variables";

#custom-card {

    .test {
        width: 100%;
        max-height: 100%;
        height: 100px;
        object-fit: scale-down;
    }


    .card-body {
        display: contents;
    }
}